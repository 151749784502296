import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppsStore } from '@app/core/store/apps/apps.store';
import { IApplication, IApplicationClassification, IApplicationSearchParams } from '@root/src/app/core/model/applications/applications';
import { AppLauncherService } from '@root/src/app/core/services/app-launcher/app-launcher.service';
import { BehaviorSubject, ReplaySubject, combineLatest } from "rxjs";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { SubSink } from 'subsink';

@Component({
  selector: 'app-bs-app-launcher',
  templateUrl: './bs-app-launcher.component.html',
  styleUrls: ['./bs-app-launcher.component.scss']
})
export class BsAppLauncherComponent implements OnInit, OnDestroy {

  @Input() public createAppLaunchUrl: (appData: any) => string;
  @Input() public canFilter: boolean = true;
  @Input() public appPropertiesToSearch = ["Name", "CompanyName", "ShortDescription"];
  @Input() public displayLaunch: boolean = true;
  @Input() public displayExpandedContent: boolean = false;
  @Input() public initialAppClassificationName: string; 

  @Output() public versionSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('classificationDropdown', { static: false }) public classificationDropdown: any;

  public appSearchControl: FormControl = new FormControl("");
  public appClassificationControl: FormControl = new FormControl("0");
  public searchResults: IApplication[] = [];
  public searchTitle = "Search results";

  private subs = new SubSink();

  public applications: BehaviorSubject<IApplication[]> = new BehaviorSubject(null);
  public classifications: BehaviorSubject<IApplicationClassification[]> = new BehaviorSubject(null);
  public bookmarkedApps: BehaviorSubject<IApplication[]> = new BehaviorSubject(null);

  public displayedApplications: BehaviorSubject<IApplication[]> = new BehaviorSubject(null);
  public displayedClassifications: BehaviorSubject<IApplicationClassification[]> = new BehaviorSubject(null);

  public appResultsMessage: BehaviorSubject<string> = new BehaviorSubject(null);
  private updateDisplayedApplications$: ReplaySubject<boolean> = new ReplaySubject(1);

  

  public contentStatus: 'collapsed' | 'expanded';
  
  public loading: boolean = true;
  public skeletonCards = Array(5).fill(4);

  constructor(
    private appLauncherService: AppLauncherService,
    public elementRef: ElementRef,
    private appsStore: AppsStore
  ) {
    this.subs.sink = this.appLauncherService.getApps().subscribe(this.applications);
    this.subs.sink = this.appLauncherService.getAppClassifications().subscribe(this.classifications);
    this.subs.sink = this.appLauncherService.getBookmarkedApps().subscribe(this.bookmarkedApps);
  }

  ngOnInit() {
    // To get the loaded apps to display first time
    this.triggerUpdateDisplayedApps();

    this.appClassificationControl.valueChanges.subscribe((value) => {
      const classification = this.displayedClassifications.value.find((appClass: IApplicationClassification) => appClass.Id === value);

      if(classification && this.classificationDropdown) {
        this.classificationDropdown.selectLabel = classification.Description;
        this.triggerUpdateDisplayedApps();
      }
    });

    this.subs.sink = combineLatest(
      this.applications.pipe(filter((value) => !!value)), 
      this.classifications.pipe(filter((value) => !!value))
    ).pipe(
      tap(([applications, classifications]) => {
        const initialDisplayedClassifications: IApplicationClassification[] = classifications.map((classification: IApplicationClassification, index: number) => {
          const classificationCount = this.getClassificationAppCount(classification);
          return {
            Name: classification.Name,
            Description: `${classification.Description} (${classificationCount})`,
            Id: `${index + 1}`
          }
        });
        initialDisplayedClassifications.splice(0, 0, { Name: "", Description: "All Categories", Id: "0" });

        this.displayedClassifications.next(initialDisplayedClassifications);
        if(this.loading) {
          this.loading = false;

          // Set initial classification dropdown value
          let initialClassification: IApplicationClassification;

          if(this.initialAppClassificationName) {
            initialClassification = this.displayedClassifications.value.find((appClass: IApplicationClassification) => appClass.Name === this.initialAppClassificationName);
          }

          if(initialClassification) {
            this.appClassificationControl.setValue(initialClassification.Id);
          } else {
            this.appClassificationControl.setValue(this.displayedClassifications.value[0].Id);
          }
        }
      }),
      switchMap(() => this.updateDisplayedApplications$),
      debounceTime(100)
    ).subscribe(() => {
      this.updateDisplayedApplications();
    });

    this.displayedApplications.pipe(
      filter((displayedApplications) => !!displayedApplications)
    ).subscribe((displayedApplications) => {
      if(this.appSearchControl.value.length > 0) {
        if(displayedApplications.length === 1) {
          this.appResultsMessage.next(`${displayedApplications.length} App Found`);
        } else {
          this.appResultsMessage.next(`${displayedApplications.length} Apps Found`);
        }
        
        this.contentStatus = 'expanded';
      } else if(displayedApplications.length === 0) {
        this.appResultsMessage.next(`No Apps Found`);
        this.contentStatus = 'collapsed';
      } else {
        this.appResultsMessage.next(null);
        this.contentStatus = 'collapsed';
      }
    });
  }

  toggleAppBookmark(appData: IApplication): void {
    this.appsStore.toggleAppBookmark(appData);
  }

  triggerUpdateDisplayedApps(): void {
    this.updateDisplayedApplications$.next(true);
  }

  updateDisplayedApplications(): void {
    const searchText = this.appSearchControl.value;
    const classificationId = this.appClassificationControl.value;
    const classification = this.displayedClassifications.value.find((appClass: IApplicationClassification) => appClass.Id === classificationId);
    if(classification) {
      const searchParams: IApplicationSearchParams = { text: searchText, classification: classification };
      this.displayedApplications.next(this.searchApps(this.applications.value, searchParams));
    }
  }

  private getClassificationAppCount(classification: IApplicationClassification) {
    let appCount = 0;
    this.applications.value
    .forEach( (a) => {
        if (a.Classifications.includes(classification.Name)) {
            appCount++;
        }
    });
    return appCount;
};

  private appMatchesSearchText(app: IApplication, searchText: string): boolean {
    return this.appPropertiesToSearch.some((propertyName) => {
      return app[propertyName] && app[propertyName].toLowerCase().includes(searchText.toLowerCase());
    });
  }

  private appMatchesClassification(app: IApplication, searchClassification: IApplicationClassification): boolean {
    return app.Classifications.includes(searchClassification.Name);
  };

  private searchApps(
    applications: IApplication[],
    searchParams: IApplicationSearchParams
  ): IApplication[] {
    let output: IApplication[] = applications;
    if (searchParams.text && searchParams.classification.Name) {
      const filterTextToMatch = searchParams.text.toLowerCase();
      output = applications.filter((application: IApplication) => {
        const textMatch = this.appMatchesSearchText(application, filterTextToMatch);
        const classificationMatch = this.appMatchesClassification(application, searchParams.classification);
        return textMatch && classificationMatch;
      });
    } else if (searchParams.text) {
      const filterTextToMatch = searchParams.text.toLowerCase();
      output = applications.filter((application: IApplication) => {
        return this.appMatchesSearchText(application, filterTextToMatch);
      });
    } else if (searchParams.classification.Name) {
      output = applications.filter((application: IApplication) => {
        return this.appMatchesClassification(application, searchParams.classification);
      });
    }

    return output;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
