<div (click)="navigateToAppDetailsPage()" class="bs-app-card" [ngClass]="{ 'bs-app-card--collapsed': !displayExpandedContent }" >
  <div class="bs-app-card__container">
    <div class="bs-app-card__content" [ngClass]="{ 'bs-app-card--expanded': displayExpandedContent }">
      <div class="bs-app-card__collapsed-content">
        <img 
          class="bs-app-card__image" 
          appImgFallback 
          [attr.src]="appData?.HrefLogo" 
          loading="lazy" 
          alt="App logo"
         />
        <div class="bs-app-card__metadata">
          <h4 class="bs-app-card__name">{{ appData?.Name }}</h4>
          <h5 class="bs-app-card__company">{{ appData?.CompanyName }}</h5>

          <div *ngIf="displayLaunch" 
            class="bs-app-card__launch-link-container">
            <a 
            class="bs-app-card__launch-link"
            (click)="navigateToAppLaunchPage($event)" 
            >LAUNCH</a>
          </div>
        </div>

        <ng-container *ngIf="displayBookmark">
          <rad-icon-button 
          *ngIf="!appData?.Bookmarked"
          class="rad-theme--light"
          (click)="toggleAppBookmark($event)" 
          appearance="dark" 
          icon-name="bookmark">
        </rad-icon-button>

        <rad-icon-button 
          *ngIf="appData?.Bookmarked"
          class="rad-theme--light"
          (click)="toggleAppBookmark($event)" 
          appearance="dark" 
          icon-name="bookmarkFilled">
        </rad-icon-button>
        </ng-container>

       
          
      </div>
  
      <div *ngIf="displayExpandedContent" class="bs-app-card__exanded-content">
        <div *ngIf="appData?.ShortDescription" class="bs-app-card__description">
          <p>{{formatShortDescription(appData?.ShortDescription)}}</p>
        </div>
        <h5 class="bs-app-card__categories bs-app-categories"  *ngIf="appData?.Classifications.length > 0" >
          Categories: 
          <span 
          *ngFor="let classification of appData?.Classifications" 
          class="bs-app-categories__item" >{{getClassificationDescription(classification)}}</span>
        </h5>
      </div>
  
    </div>
  </div>
    
</div>
  
  