import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IApplication, IApplicationClassification } from '@root/src/app/core/model/applications/applications';

@Component({
  selector: 'app-bs-app-card',
  templateUrl: './bs-app-card.component.html',
  styleUrls: ['./bs-app-card.component.scss']
})
export class BsAppCardComponent implements OnInit {

  @Input() public appData: IApplication;
  @Input() public appClassifications: IApplicationClassification[];
  @Input() public createAppLaunchUrl: (appData: any) => string;
  @Input() public displayLaunch: boolean = false;
  @Input() public displayExpandedContent: boolean = false;
  @Input() public displayBookmark: boolean = true;

  @Output() public selectedVersion: any;
  @Output() public toggleBookmark: EventEmitter<IApplication> = new EventEmitter();

  public versions: any[] = [];
  public appDetailsPath: string;
  public appCardNavigationLink: string;

  REGEX_NON_WORDS: RegExp = /[^\w]+/g;

  constructor(private router: Router) {}

  ngOnInit() {
    this.appDetailsPath = `/apps/${this.appData.Id}/${this.toSlug(this.appData.Name)}?preferredversion`;
    this.appCardNavigationLink = this.appDetailsPath;
  }

  getClassificationDescription(classificationId: string): string {
    if(this.appClassifications) {
      const foundClassification = this.appClassifications.find((classification) => classification.Id === classificationId);
      if(foundClassification) {
        return foundClassification.Description;
      }
    }
    return classificationId;
  }

  defaultCreateAppLaunchUrl(appData: any): string {
    return `/apps/${appData.Id}/start?redirectToPreferredVersion=true`;
  }

  toSlug(str: string): string {
    return encodeURIComponent(str.replace(this.REGEX_NON_WORDS, "-"));
  }

  toggleAppBookmark(event) {
    event.stopPropagation();
    this.toggleBookmark.emit(this.appData);
  }

  formatShortDescription(shortDescription: string): string {
    return shortDescription.substring(0, 120);
  }

  navigateToAppDetailsPage(): void {
    this.router.navigateByUrl(this.appCardNavigationLink);
  }

  navigateToAppLaunchPage(event: MouseEvent): void {
    event.stopPropagation();
    const redirectLink = this.createAppLaunchUrl ? this.createAppLaunchUrl(this.appData) : this.defaultCreateAppLaunchUrl(this.appData);
    this.router.navigateByUrl(redirectLink);
  }
}